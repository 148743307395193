
import DashboardFooter from '@bd/client/components/Dashboard/DashboardFooter.vue'
import { useAppStore } from '@bd/client/store'
import {
  DashboardOfferDescription,
  DashboardOfferDetails,
  DashboardOfferGallery,
  DashboardOfferMap,
  DashboardOfferSidebar,
  DashboardOfferFooter,
  DashboardOfferUser,
  OfferFooterButtons,
  PageContent,
  useResourceDataState,
  EmptyState,
  useTextOrPlaceholder,
  UserType,
} from '@bd/components'
import { useUserData } from '@bd/components/use'
import { computed, defineComponent, ref, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { commonApi } from '@bd/api'
import { TransactionType } from '@bd/api/common/types/transaction'
import ProgressSpinner from 'primevue/progressspinner'

export default defineComponent({
  name: 'DashboardOffer',
  components: {
    DashboardOfferGallery,
    DashboardOfferMap,
    DashboardOfferSidebar,
    DashboardOfferDetails,
    DashboardOfferDescription,
    DashboardOfferFooter,
    PageContent,
    DashboardFooter,
    DashboardOfferUser,
    ProgressSpinner,
    EmptyState,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const route = useRoute()
    const { isLogged } = useUserData()
    const showAppointmentModal = ref(false)

    const loadOffer = (id: number) => {
      store.commit('offers/SET_OFFER', undefined)
      store.dispatch('offers/fetchOffer', id)
    }

    const offer = computed(() => store.state.offers?.offerDetails)
    const { isLoading, isEmpty } = useResourceDataState(offer)

    const displayedFooterButtons: OfferFooterButtons[] = [
      'openAppointment',
      'openProposal',
    ]

    const title = computed(() => offer.value?.title)
    const titleValue = useTextOrPlaceholder(title, {
      placeholder: t('offerDetails.no_title'),
    })

    const openAppointmentModal = async () => {
      if (isLogged.value) {
        store.dispatch('dialogVisibility', {
          appointmentModal: { offerId: offer.value?.id },
        })
      } else {
        store.dispatch('dialogVisibility', {
          dashboardLoginModal: { reason: 'appointment' },
        })
      }
    }

    const proposalMessage = computed(
      () => store.state.purchaseOffers?.purchaseMessage,
    )
    const openProposalModal = async () => {
      if (!isLogged.value) {
        store.dispatch('dialogVisibility', {
          dashboardLoginModal: { reason: 'proposal' },
        })
        return
      }

      const offerId = +route.params.id

      if (!proposalMessage.value) {
        const { data } = await commonApi.purchaseOfferAvailability(offerId)

        const wasAnyProposalIssued = !data.value
        if (wasAnyProposalIssued) {
          await store.dispatch('purchaseOffers/lastPurchaseOffer', {
            offerId,
          })
          store.dispatch('dialogVisibility', {
            counterProposalModal: true,
          })
        } else {
          const purchaseOffer = {
            offerId,
            price: offer.value?.price,
            type: TransactionType.SELLER_TO_BUYER,
          }
          store.commit('purchaseOffers/SET_PURCHASE', purchaseOffer)
          store.dispatch('dialogVisibility', {
            counterProposalModal: true,
          })
        }
      }
    }

    const clearProposalData = () => {
      store.commit('purchaseOffers/CLEAR_ERROR')
      store.commit('purchaseOffers/CLEAR_LAST_PURCHASE')
      store.commit('purchaseOffers/CLEAR_PURCHASE_MESSAGE')
    }
    const isGalleryFullscreen = ref(false)
    const onGalleryFullscreenToggle = (isFullscreen: boolean) => {
      isGalleryFullscreen.value = isFullscreen
    }

    loadOffer(+route.params.id)
    onBeforeUnmount(clearProposalData)

    return {
      t,
      offer,
      openAppointmentModal,
      showAppointmentModal,
      openProposalModal,
      proposalMessage,
      isLoading,
      isEmpty,
      titleValue,
      displayedFooterButtons,
      onGalleryFullscreenToggle,
      isGalleryFullscreen,
      UserType,
    }
  },
})

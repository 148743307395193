<template>
  <PageContent
    class="offer-content"
    :class="{ 'is-loading': isLoading, 'is-empty': isEmpty }"
    :showMobileHeader="!isGalleryFullscreen"
    :headerLabel="t('offers.details')"
    :backRoute="{ name: 'Dashboard' }"
  >
    <template v-if="!isLoading && !isEmpty">
      <div class="content-header-wrapper">
        <div class="container-fluid container-lg py-3">
          <div class="content-header">
            <div class="back-button">
              <Button
                @click="$router.back()"
                icon="pi pi-chevron-left"
                class="p-button-secondary p-button-circle"
              />
            </div>
            <h1 class="offer-title text-wrap-2">{{ titleValue }}</h1>
            <DashboardOfferUser
              :typeOfUser="UserType.Agent"
              :userData="offer.offerAgentDto"
              class="offer-keeper"
            />
          </div>
        </div>
      </div>

      <div class="container-fluid container-lg dashboard-offer-padding">
        <div class="content-wrapper">
          <div class="main-column">
            <DashboardOfferGallery
              :offerDetails="offer"
              @toggleFullscreen="onGalleryFullscreenToggle"
            />
            <div>
              <h3>{{ t('location') }}</h3>
              <DashboardOfferMap :offerDetails="offer" />
            </div>
            <div>
              <h3>{{ t('property_description') }}</h3>
              <DashboardOfferDescription
                :description="offer?.description || ''"
              />
            </div>
            <div>
              <h3>{{ t('more_details') }}</h3>
              <DashboardOfferDetails :offerDetails="offer" />
            </div>
          </div>
          <DashboardOfferSidebar
            :displayedButtons="displayedFooterButtons"
            :offerDetails="offer"
            :proposalMessage="proposalMessage"
            :displayedUsers="['agent']"
            @openProposal="openProposalModal"
            @openAppointment="openAppointmentModal"
            @toggleGalleryFullscreen="onGalleryFullscreenToggle"
          />
        </div>
      </div>
    </template>
    <template v-if="isLoading">
      <div class="progress-container">
        <ProgressSpinner />
      </div>
    </template>
    <template v-if="isEmpty && !isLoading">
      <EmptyState :title="t('offerDetails.empty_state.title')">
        <Button
          icon="pi pi-arrow-left"
          class="p-button-primary px-3"
          :label="t('offerDetails.empty_state.back')"
          @click="$router.push({ name: 'Dashboard' })"
        />
      </EmptyState>
    </template>
    <DashboardOfferFooter
      v-if="!isGalleryFullscreen"
      class="mobile-offer-footer"
      :displayedButtons="displayedFooterButtons"
      :offerDetails="offer"
      @openAppointment="openAppointmentModal"
      @openProposal="openProposalModal"
    />
    <DashboardFooter />
  </PageContent>
</template>

<script lang="ts">
import DashboardFooter from '@bd/client/components/Dashboard/DashboardFooter.vue'
import { useAppStore } from '@bd/client/store'
import {
  DashboardOfferDescription,
  DashboardOfferDetails,
  DashboardOfferGallery,
  DashboardOfferMap,
  DashboardOfferSidebar,
  DashboardOfferFooter,
  DashboardOfferUser,
  OfferFooterButtons,
  PageContent,
  useResourceDataState,
  EmptyState,
  useTextOrPlaceholder,
  UserType,
} from '@bd/components'
import { useUserData } from '@bd/components/use'
import { computed, defineComponent, ref, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { commonApi } from '@bd/api'
import { TransactionType } from '@bd/api/common/types/transaction'
import ProgressSpinner from 'primevue/progressspinner'

export default defineComponent({
  name: 'DashboardOffer',
  components: {
    DashboardOfferGallery,
    DashboardOfferMap,
    DashboardOfferSidebar,
    DashboardOfferDetails,
    DashboardOfferDescription,
    DashboardOfferFooter,
    PageContent,
    DashboardFooter,
    DashboardOfferUser,
    ProgressSpinner,
    EmptyState,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const route = useRoute()
    const { isLogged } = useUserData()
    const showAppointmentModal = ref(false)

    const loadOffer = (id: number) => {
      store.commit('offers/SET_OFFER', undefined)
      store.dispatch('offers/fetchOffer', id)
    }

    const offer = computed(() => store.state.offers?.offerDetails)
    const { isLoading, isEmpty } = useResourceDataState(offer)

    const displayedFooterButtons: OfferFooterButtons[] = [
      'openAppointment',
      'openProposal',
    ]

    const title = computed(() => offer.value?.title)
    const titleValue = useTextOrPlaceholder(title, {
      placeholder: t('offerDetails.no_title'),
    })

    const openAppointmentModal = async () => {
      if (isLogged.value) {
        store.dispatch('dialogVisibility', {
          appointmentModal: { offerId: offer.value?.id },
        })
      } else {
        store.dispatch('dialogVisibility', {
          dashboardLoginModal: { reason: 'appointment' },
        })
      }
    }

    const proposalMessage = computed(
      () => store.state.purchaseOffers?.purchaseMessage,
    )
    const openProposalModal = async () => {
      if (!isLogged.value) {
        store.dispatch('dialogVisibility', {
          dashboardLoginModal: { reason: 'proposal' },
        })
        return
      }

      const offerId = +route.params.id

      if (!proposalMessage.value) {
        const { data } = await commonApi.purchaseOfferAvailability(offerId)

        const wasAnyProposalIssued = !data.value
        if (wasAnyProposalIssued) {
          await store.dispatch('purchaseOffers/lastPurchaseOffer', {
            offerId,
          })
          store.dispatch('dialogVisibility', {
            counterProposalModal: true,
          })
        } else {
          const purchaseOffer = {
            offerId,
            price: offer.value?.price,
            type: TransactionType.SELLER_TO_BUYER,
          }
          store.commit('purchaseOffers/SET_PURCHASE', purchaseOffer)
          store.dispatch('dialogVisibility', {
            counterProposalModal: true,
          })
        }
      }
    }

    const clearProposalData = () => {
      store.commit('purchaseOffers/CLEAR_ERROR')
      store.commit('purchaseOffers/CLEAR_LAST_PURCHASE')
      store.commit('purchaseOffers/CLEAR_PURCHASE_MESSAGE')
    }
    const isGalleryFullscreen = ref(false)
    const onGalleryFullscreenToggle = (isFullscreen: boolean) => {
      isGalleryFullscreen.value = isFullscreen
    }

    loadOffer(+route.params.id)
    onBeforeUnmount(clearProposalData)

    return {
      t,
      offer,
      openAppointmentModal,
      showAppointmentModal,
      openProposalModal,
      proposalMessage,
      isLoading,
      isEmpty,
      titleValue,
      displayedFooterButtons,
      onGalleryFullscreenToggle,
      isGalleryFullscreen,
      UserType,
    }
  },
})
</script>
<style lang="scss" scoped>
h1,
h3 {
  font-size: 1.4rem;
  font-weight: 600;
  color: $port-gore;
  display: flex;
  align-items: center;
  height: 69px;
}

.offer-title {
  line-height: normal;
  flex: 1;
  height: 100%;
  margin-right: 1rem;
  min-height: 30px;

  @include breakpoint-down(md) {
    display: none;
  }
}

.offer-keeper {
  flex: 0 0 28%;

  @include breakpoint-down(md) {
    display: none;
  }
}

.content-wrapper {
  @include breakpoint-up(md) {
    display: flex;
    justify-content: space-between;
  }
  .main-column {
    @include breakpoint-down(md) {
      display: none;
    }
    width: 65%;
    display: flex;
    flex-direction: column;
  }
}

.dashboard-offer-padding {
  padding: 0;
  @include breakpoint-up(md) {
    padding: 1rem;
  }
}

.content-header-wrapper {
  background: $alabaster;
  @include breakpoint-down(md) {
    display: none;
  }
}

.content-header {
  display: flex;
  align-items: center;
  position: relative;
}

.back-button {
  position: absolute;
  left: -4rem;
}

:deep(.offer-content) {
  width: 100%;
  margin: 0 auto;
  overflow-y: auto;
  @include breakpoint-down(md) {
    height: calc(
      100vh - #{$mobile-header-height} - #{$dashboard-offer-footer-height}
    );
  }

  &.is-loading,
  &.is-empty {
    height: calc(100vh - #{$top-navigation-height});
    @include breakpoint-down(md) {
      height: 100vh;
    }
  }
}

.mobile-offer-footer {
  @include breakpoint-up(md) {
    display: none;
  }
}
</style>

<template>
  <footer>
    <div>
      <div class="logo-wrapper">
        <a class="link-new" :href="`${domainURL}biuro/`">
          <img src="@bd/assets/images/immoby-horizontal-logo.svg" />
        </a>
      </div>
      <p class="copyright">{{ immobiCopyright }}</p>
    </div>
    <div class="links">
      <a class="terms-link" :href="`${domainURL}biuro/polityka-prywatnosci/`">
        {{ t('privacy_policy') }}
      </a>
      <a class="terms-link" :href="`${domainURL}biuro/regulamin/`">
        {{ t('consent_2') }}
      </a>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { default as common } from '@/config/common.json'

const domainURL = process.env.VUE_APP_DOMAIN_URL

export default defineComponent({
  name: 'DashboardFooter',
  setup() {
    const i18n = useI18n()
    const { immobiCopyright } = common

    return { ...i18n, immobiCopyright, domainURL }
  },
})
</script>
<style lang="scss" scoped>
footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 1rem 2rem;
  border-top: $divider-border;
  .logo-wrapper {
    padding: 0 15px;
  }
  .copyright {
    height: 0.625rem;
  }
  .links {
    display: flex;
    justify-content: space-between;
    text-align: right;
    a {
      min-width: 8rem;
    }
  }
  div {
    align-items: center;
    display: flex;
    font-size: 1rem;
    min-width: 8rem;
  }
  a,
  p {
    text-decoration: none;
    color: $comet;
  }
  @include breakpoint-down(md) {
    display: none;
  }
}
</style>


import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { default as common } from '@/config/common.json'

const domainURL = process.env.VUE_APP_DOMAIN_URL

export default defineComponent({
  name: 'DashboardFooter',
  setup() {
    const i18n = useI18n()
    const { immobiCopyright } = common

    return { ...i18n, immobiCopyright, domainURL }
  },
})
